import { Box, Button, Grid, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, Stack, Flex, Spacer, Input, FormControl, FormLabel, InputLeftElement, InputGroup, IconButton, useColorModeValue } from "@chakra-ui/react";
import SamplerequestSkeleton from "../../components/Samplerequest/SamplerequestSkeleton";
import Samplerequests from '../../components/Samplerequest/Samplerequests';
import { useQuery } from '@tanstack/react-query';
import { ISamplerequestDetail, ISamplerequestList } from "../../types";
import { getMe, getSamplerequest, getSamplerequests } from '../../api';
import { SearchIcon } from "@chakra-ui/icons";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

// interface ISamplerequestItems {
//     item: string;
//     qty: number;
//     unit_price: number;
//     amount: number;
//     serial_number: string;

// }

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}

export default function Samplerequest(props: {
	variant?: string;
	background?: string;
	children?: JSX.Element;
	placeholder?: string;
	borderRadius?: string | number;
	[x: string]: any;
}) {

    const { variant, background, children, placeholder, borderRadius, ...rest } = props;
	// Chakra Color Mode
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const inputText = useColorModeValue('gray.700', 'gray.100');
    const [page, setPage] = useState(1)

    const { isLoading, data, isPreviousData } = useQuery<ISamplerequestList[]>(["samplerequests"], getSamplerequests);
    const { data: samplerequestDetailData } = useQuery<ISamplerequestDetail>([`samplerequests`], getSamplerequest);

    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);
    
    const minimax_number = userData?.minimax_number;
    const [query, setQuery] = useState("")

    const nextPage = () => setPage(prev=> prev+1)
    const prevPage = () => setPage(prev=> prev-1)
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let inputHandler = (e:any) => {
                var lowerCase = e.target.value.toLowerCase();
                setQuery(lowerCase)
            }
        },3000)
        return () => clearTimeout(delayDebounceFn)
    }, [])    


    const exportToExcel = () => {
        if (!data || data.length === 0) {
            console.error("No data available to export");
            return;
        }
        if(userData?.role == "Boss" || userData?.role == "Manager" || userData?.name == "Charlie Hong"  ||  userData?.name ==="Sean Hyun" || userData?.name === "Skylar Jang" || userData?.name === "Prisca Yong"  || userData?.name === "Ellena Sim") {
            const transformedData = data.map((item) => ({
                ...item,
                submitterName: item.submitter.name, // submitter의 name 필드를 추가
                samplerequestItem: item.samplerequest_items.map(samplerequest_item => samplerequest_item.item).join(", "), 
                samplerequestSerialnumber: item.samplerequest_items.map(samplerequest_item => samplerequest_item.serial_number).join(", "),
                samplerequestItemQty: item.samplerequest_items.map(samplerequest_item => samplerequest_item.qty).join(", "), 
                samplerequestItemUnitprice: item.samplerequest_items.map(samplerequest_item => samplerequest_item.unit_price).join(", "), 
                samplerequestItemAmount: item.samplerequest_items.map(samplerequest_item => samplerequest_item.amount).join(", "), 
            }));
             // WorkSheet 생성
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformedData);
            // WorkBook 생성
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, "data.xlsx");
        } else {
            const filteredData = data.filter(item => item.submitter.name === userData?.name);

            // 데이터 변환
            const transformedData = filteredData.map((item) => ({
            ...item,
            submitterName: item.submitter.name,
                samplerequestItem: item.samplerequest_items.map(samplerequest_item => samplerequest_item.item).join(", "), 
                samplerequestSerialnumber: item.samplerequest_items.map(samplerequest_item => samplerequest_item.serial_number).join(", "),
                samplerequestItemQty: item.samplerequest_items.map(samplerequest_item => samplerequest_item.qty).join(", "), 
                samplerequestItemUnitprice: item.samplerequest_items.map(samplerequest_item => samplerequest_item.unit_price).join(", "), 
                samplerequestItemAmount: item.samplerequest_items.map(samplerequest_item => samplerequest_item.amount).join(", "),  // submitter의 name 필드를 추가
            }));
             // WorkSheet 생성
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformedData);
            // WorkBook 생성
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, "data.xlsx");
        }
    };


    let samplerequestList;
    function RoleCheck() {
        
        if(userData?.role == "Boss" || userData?.role == "Manager" || userData?.name == "Charlie Hong"  ||  userData?.name ==="Sean Hyun" || userData?.name === "Skylar Jang" || userData?.name === "Prisca Yong" || userData?.name === "Richard Noh" || userData?.name === "Ellena Sim"  || userData?.name === "Steve Kim") {
            return <Tbody>
            {data?.filter((data) => {
                if(query === '') { 
                    return data;
                } else if
                (data.sr_id.toLowerCase().includes(query.toLowerCase()) || data.submitter.name.toLowerCase().includes(query.toLowerCase())  || data.description.toLowerCase().includes(query.toLowerCase())) {
                    return data;
                } 
            }).map((samplerequest) => (
            <Samplerequests
            key={samplerequest.id}
            id={samplerequest.id}
            sr_id={samplerequest.sr_id}
            srdrname={samplerequest.srdrname}
            submitter={samplerequest.submitter.name}
            kind={samplerequest.kind}
            demo_kind={samplerequest.demo_kind}
            demo_tested={samplerequest.demo_tested}
            sr_submission_date={samplerequest.sr_submission_date}
            srclinicname={samplerequest.srclinicname}
            srcliniccode={samplerequest.srcliniccode}
            srbillingemail={samplerequest.srbillingemail}
            description={samplerequest.description}
            samplerequest_items={samplerequest.samplerequest_items}
            sr_total_price={samplerequest.sr_total_price}
            sr_gst_price={samplerequest.sr_gst_price}
            sr_final_price={samplerequest.sr_final_price}

            />
            ))}
            </Tbody>
        }
        // if(userData?.role == "Boss") {
        //     return <Tbody>
        //     {data?.filter((data) => {
        //         if(query === '') { 
        //             return data;
        //         } else if
        //         (data.quotation_id.toLowerCase().includes(query) || data.user.name.toLowerCase().includes(query) || data.client.name.toLowerCase().includes(query) || data.client.clinic.toLowerCase().includes(query) || data.title.toLowerCase().includes(query) || data.kind.toLowerCase().includes(query)) {
        //             return data;
        //         } 
        //     }).map((quotation) => (
        //     <Quotations
        //     key={quotation.id}
        //     id={quotation.id}
        //     quotation_id={quotation.quotation_id}
        //     date={quotation.date}
        //     expire_date={quotation.expire_date}
        //     name={quotation.user.name}
        //     dentist={quotation.client.name}
        //     clinic={quotation.client.clinic}
        //     address={quotation.client.address}
        //     mobile={quotation.client.mobile}
        //     email={quotation.client.email}
        //     abn={quotation.client.abn}
        //     kind={quotation.kind}
        //     title={quotation.title}
        //     quotation_items={quotation.quotation_items}
        //     quotetncs={quotation.quotetncs}
        //     quote_total_price={quotation.quote_total_price}
        //     quote_gst_price={quotation.quote_gst_price}
        //     quote_final_price={quotation.quote_final_price}
        //     />
        //     ))}
        //     </Tbody>
        // }
        else if(userData?.role == "Sales Team") {
            return <Tbody>
            {data?.filter(data=>data.submitter.minimax_number==minimax_number).filter((data) => data.submitter.name.toLowerCase().includes(query)).map((samplerequest) => (
            samplerequestList = <Samplerequests
            key={samplerequest.id}
            id={samplerequest.id}
            sr_id={samplerequest.sr_id}
            srdrname={samplerequest.srdrname}
            submitter={samplerequest.submitter.name}
            kind={samplerequest.kind}
            demo_kind={samplerequest.demo_kind}
            demo_tested={samplerequest.demo_tested}
            sr_submission_date={samplerequest.sr_submission_date}
            srclinicname={samplerequest.srclinicname}
            srcliniccode={samplerequest.srcliniccode}
            srbillingemail={samplerequest.srbillingemail}
            description={samplerequest.description}
            samplerequest_items={samplerequest.samplerequest_items}
            sr_total_price={samplerequest.sr_total_price}
            sr_gst_price={samplerequest.sr_gst_price}
            sr_final_price={samplerequest.sr_final_price}
            /> 
            ))}
            </Tbody>
        }
        return samplerequestList = <Tbody><Tr><Td></Td></Tr></Tbody>    
    }
    return (
        <Grid mt={10} columnGap={7} rowGap={5} templateColumns={{ base: "repeat(1fr)", lg: "repeat(1fr)"}} px={{base: 0, lg: 10}}>
            {isLoading ? (
            <>
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
                <SamplerequestSkeleton />
            </>
            ) : null }
            
            <Flex>
            <FormControl>
            <InputGroup w={{ base: '100%', md: '50%' }} {...rest}>
                <InputLeftElement
                    children={
                        <IconButton
                            aria-label='search'
                            bg='none'
                            borderRadius='inherit'
                            _hover={{
                                bg: 'none',
                            }}
                            _active={{
                                bg: 'none',
                                transform: 'none',
                                borderRadius: 'inherit',
                                borderColor: 'transparent'
                            }}
                            _focus={{
                                boxShadow: 'none',
                                borderRadius:'inherit'
                            }}
                            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' borderRadius='inherit'
                            />}
                        />
                    }
                />
                <Input
                    variant='search'
                    border="1px solid #f0f0f0"

                    fontSize='sm'
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    borderRadius={borderRadius ? borderRadius : '30px'}
                    placeholder={placeholder ? placeholder : 'Search...'}
                    type="text"
                    onChange={(e) => setQuery(e.target.value)}
                    />

            </InputGroup>
            </FormControl>
            
            <Spacer />
            {/* {userData?.role == "Boss" || userData?.role == "Manager" || userData?.name == "Charlie Hong"  ||  userData?.name ==="Sean Hyun" || userData?.name === "Skylar Jang" || userData?.name === "Prisca Yong" && (
            <> */}
            <Button onClick={exportToExcel} colorScheme="teal" mr={3}  size="sm">
                엑셀
            </Button>
            {/* </>
            )} */}
            <Link href="/samplerequests/upload">
                <Button size="sm">+ 새 Request Form 만들기</Button>
            </Link>
            </Flex>
            <Box>
                <HStack>
                    <TableContainer w="100%">
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>No</Th>
                                    {userData?.role == "Boss" && (
                                    <Th>수정</Th>
                                    )}
                                    {userData?.role == "Manager" && (
                                    <Th>수정</Th>
                                    )}
                                    {userData?.name === "Charlie Hong" && (
                                    <Th>수정</Th>
                                    )}
                                    {userData?.name ==="Sean Hyun" && (
                                    <Th>수정</Th>
                                    )}
                                    {userData?.name === "Skylar Jang" && (
                                    <Th>수정</Th>
                                    )}
                                    {userData?.name === "Prisca Yong" && (
                                    <Th>수정</Th>
                                    )}
                                    {userData?.name === "Ellena Sim" && (
                                    <Th>수정</Th>
                                    )}
                                    {userData?.name === "Steve Kim" && (
                                    <Th>수정</Th>
                                    )}
                                    <Th>날짜</Th>
                                    <Th>타입</Th>
                                    <Th>세일즈</Th>
                                    <Th>치과의사</Th>
                                    <Th>치과이름</Th>
                                    <Th>설명</Th>
                                </Tr>
                            </Thead>
                            <RoleCheck />
                            {/* <Box>
                                <Button onClick={prevPage} disabled={isPreviousData || page === 1}>Prev</Button>
                                <Button onClick={nextPage} disabled={isPreviousData}>Next</Button>
                            </Box> */}
                        </Table>
                    </TableContainer>
                </HStack>
            </Box>
        </Grid>
    )
}  