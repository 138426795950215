import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom"
import { getMe, getSalesAgreement } from "../../api";
import { ISalesAgreementDetail, IUser } from "../../types";
import SalesAgreementDetailSkeleton from "../../components/SalesAgreement/SalesAgreementDetailSkeleton";
import { Box, Button, calc, Container, Flex, HStack } from "@chakra-ui/react";
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import Footer from "../../components/PDF/SalesAgreement/Footer";
import Header from "../../components/PDF/SalesAgreement/Header";
import Middle1 from "../../components/PDF/SalesAgreement/Middle1";
import { VscFilePdf } from "react-icons/vsc";
import { useMemo } from "react";

import Opensans1 from "../../components/font/open-sans-700.ttf"
import { CheckIcon } from "@chakra-ui/icons";

Font.register({
    family: 'Open Sans',
    fonts: [
      {
        src: Opensans1,
      },
      
    ],
});
const styles = StyleSheet.create({
    body: {
        paddingLeft: "60px", paddingRight: "60px", paddingTop: "30px",
    },
    body_m: {
        paddingLeft: "60px", paddingRight: "60px", paddingTop: "30px",
    },
    body1: {
        paddingLeft: "60px", paddingRight: "60px",
    },
    top: {
        flex: 1.3,
    },
    middle1: {
        flex: 2,
    },
    middle2: {
        flex: 5,

    },
    bottom: {
        justifyContent: "flex-end",
        flex: 2,
        fontSize: 8,
    },
    subtitle: {
        fontSize: 10,
        marginBottom: "6px",
        fontWeight: 700,
        fontFamily: "Open Sans"
    },
    sub1: {
        display: 'flex',
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    sub1D: {
        flexDirection: 'row',
    },
    sub2: {
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    list0: {
        backgroundColor: '#ffffff',
        fontSize: 8,
        lineHeight: 1.4,
        paddingLeft: 16,
        paddingRight: 16,
        display: 'flex',
    },
    list: {
        backgroundColor: '#ffffff',
        fontFamily: 'Open Sans',
        fontSize: "7.5px",
        lineHeight: 1.4,
        paddingTop: 0,
        paddingLeft: 16,

    },
    signature: {
        display: 'flex',
        paddingLeft: "20px",
        paddingRight: "20px",
        },
    signaguresub: {
        flexDirection: 'row',
        justifyContent: "space-between",
    },
    check: {
        width: "14px",
        height: "14px",
        backgroundColor: '#fff',
        border: '1px solid #000',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      },
      checkmark: {
        position: 'absolute',
        width: "7px",
        height: "7px",
        borderBottom: '2px solid #000',
        borderRight: '2px solid #000',
        transform: 'rotate(45deg)',
        top: "0.9px"
      },
})

export default function SalesAgreementDetail() {
    const { salesagreementPk } = useParams();
    const navigate = useNavigate();

    const { isLoading, data } = useQuery<ISalesAgreementDetail>([`salesagreements`, salesagreementPk], getSalesAgreement);
    const { data: userData } = useQuery<IUser>([`users`], getMe);
    const minimax_number = userData?.minimax_number;
    const scdentist = data?.client.name!
    const scclinic = data?.client.clinic!
    const scaddress = data?.client.address!
    const scmobile = data?.client.mobile!
    const scemail = data?.client.email!
    const scabn = data?.client.abn!
    const sid = data?.salesagreement_id!
    const suname = data?.user.name!
    const sdate = data?.date!
    const spaymentterm = data?.payment_term!
    const spaymentdate = data?.payment_date!
    const spaymentday = data?.payday!
    const spaymentmonth = data?.paymonth!
    const spaymentyear = data?.payyear!
    const spmethod = data?.payment_method!
    const scredittype = data?.credit_type!
    const screditno = data?.credit_no!
    const screditexmonth = data?.credit_exmonth!
    const screditexyear = data?.credit_exyear!
    const screditcvv = data?.credit_cvv!
    const sloancompany = data?.loan_company!
    const sloanrep = data?.loan_rep!
    const sloanmobile = data?.loan_mobile!
    const sloanemail = data?.loan_email!
    const satncs = data?.satncs!

    const implantItem = Array.isArray(satncs) ? satncs.find(item => item.name === "Implant") : null;
    const equipmentItem = Array.isArray(satncs) ? satncs.find(item => item.name === "Equipment") : null;


    const sfinalprice=Math.round(data?.sa_final_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

const MyPDF = () => (
    <Document>
        <Page>
            <View wrap>
            <View>
                <Header salesagreement_id={sid} date={sdate} clinic={scclinic} final={sfinalprice}></Header>
                <View style={styles.body}>
                    <View style={{ display: 'flex', alignItems: 'flex-end'}}>
                        <View style={{ flexDirection: 'row' }}>
                            <View><Text style={{ color: "rgba(122,122,122,0.75)", fontSize: "10px", marginRight: "10px", fontFamily: "Open Sans" }}>Sales Agreement ID:</Text></View>                        
                            <View><Text style={{ fontSize: "10px", width: "100px", textAlign: "center", borderBottom: "1px solid #f0f0f0;", fontWeight: 700, fontFamily: "Open Sans" }}>{sid}</Text></View>                        
                        </View>
                    </View>
                    <View>
                        <Text style={styles.subtitle}>1.  CLIENT</Text>
                        <View style={styles.sub1}>
                            <View style={styles.sub1D}>
                                <View style={{marginBottom: "10px", width: "12%"}}>
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", fontFamily: "Open Sans", borderBottom: "0.5px solid #f0f0f0"}}>Name:</Text>
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", fontFamily: "Open Sans", borderBottom: "0.5px solid #f0f0f0"}}>Clinic:</Text>
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", fontFamily: "Open Sans", borderBottom: "0.5px solid #f0f0f0"}}>ABN:</Text>
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", fontFamily: "Open Sans", borderBottom: "0.5px solid #f0f0f0"}}>Phone:</Text>
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", fontFamily: "Open Sans", borderBottom: "0.5px solid #f0f0f0"}}>Mobile:</Text>
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", fontFamily: "Open Sans", borderBottom: "0.5px solid #f0f0f0"}}>Email:</Text>
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", fontFamily: "Open Sans", borderBottom: "0.5px solid #f0f0f0"}}>Address:</Text>
                                </View>
                                <View style={{marginBottom: "10px", width: "88%"}}>
                                    {scdentist != "" ?
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>{scdentist}</Text>
                                    :
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>-</Text>
                                    }
                                    {scclinic != "" ?
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>{scclinic}</Text>
                                    :
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>-</Text>
                                    }
                                    {scabn != "" ?
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>{scabn}</Text>
                                    :
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>-</Text>
                                    }
                                    {scmobile != "" ?
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>{scmobile}</Text>
                                    :
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>-</Text>
                                    }
                                    {scmobile != "" ?
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>{scmobile}</Text>
                                    :
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>-</Text>
                                    }
                                    {scemail != "" ?
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>{scemail}</Text>
                                    :
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>-</Text>
                                    }   
                                    {scaddress != "" ?
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>{scaddress}</Text>
                                    :
                                    <Text style={{ color: '#000', fontSize: '9px', marginBottom: "4px", fontFamily: "Open Sans", fontWeight: 700, borderBottom: "0.5px solid #f0f0f0"}}>-</Text>
                                    }
                                </View>
                            </View>
                        </View>

                        <View>
                        <Text style={{ width: "100%", border: "0.4px solid #000", marginTop: "2px", marginBottom: "16px"}}></Text>
                        </View>

                        <Text style={styles.subtitle}>2.  PARTIES</Text>
                        <View style={styles.sub1}>
                            <View style={styles.sub1D}>
                                <View style={{marginBottom: "10px"}}>
                                    <Text style={{ color: '#292929', fontSize: '9px', marginBottom: "10px", marginRight: "10px", fontFamily: "Open Sans", fontWeight: 700}}>This Sales Agreement (the “Agreement”) is made on and effective as of  ( {sdate} )</Text>
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '8px', marginBottom: "4px", marginRight: "10px"}}>This Agreement is between MINIMAX IMPLANT PTY LTD  [ABN: 60 154 715 705] of Suite 2.3 Level 2 South Tower 394 Lane Cove Rd. Macquarie Park NSW 2113 (TEL:02 8084 2900) (“Supplier”)</Text>
                                    <View style={{ display: "flex", flexDirection: "row"}}>
                                        <View style={{width: "5%"}}>
                                            <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '8px', marginBottom: "4px", paddingRight: "10px"}}>And </Text>
                                        </View>
                                        <View style={{width: "45%"}}>
                                            <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", borderBottom: "0.5px solid #f0f0f0", paddingBottom: "2px", fontWeight: 700, fontFamily: "Open Sans"}}>[ Clinic:   {scclinic}</Text>
                                        </View>
                                        <View style={{width: "37%"}}>
                                            <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", paddingRight: "10px", paddingBottom: "2px", borderBottom: "0.5px solid #f0f0f0", fontWeight: 700, fontFamily: "Open Sans"}}>]   [ ABN:   {scabn}</Text>
                                        </View>
                                        <View style={{width: "13%"}}>
                                            <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", paddingBottom: "2px", borderBottom: "0.5px solid #f0f0f0", fontWeight: 700, fontFamily: "Open Sans"}}>]   (“Client”)</Text>
                                        </View>

                                    </View>  
                                </View>
                            </View>
                        </View>
                        <View>
                        <Text style={{ width: "100%", border: "0.4px solid #000", marginTop: "2px", marginBottom: "16px"}}></Text>
                        </View>

                        <Text style={styles.subtitle}>3.  CONSIDERATION</Text>
                        <View style={styles.sub1}>
                            <View style={styles.sub1D}>
                                <View style={{marginBottom: "10px"}}>
                                    
                                    <Text style={{ color: 'rgba(122,122,122,0.75)', fontSize: '8px', marginBottom: "4px", marginRight: "10px"}}>The Client wishes to purchase products from the Supplier on the terms and conditions contained in this Agreement.</Text>
                                </View>
                            </View>
                        </View>
                        <View>
                        <Text style={{ width: "100%", border: "0.4px solid #000", marginTop: "2px", marginBottom: "16px"}}></Text>
                        </View>

                        <Text style={styles.subtitle}>4.  PAYMENT TERM</Text>
                        <View style={styles.sub1}>
                            <View style={styles.sub1D}>
                                <View style={{marginBottom: "10px"}}>
                                    <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>{spaymentterm}</Text>
                                    {spaymentterm === "Lump Sum" && (
                                    <>
                                        {data?.salesagreement_pays.map((key, index) => {
                                        return <View key={index} style={{display: 'flex'}}>
                                            <View style={{flexDirection: "row", textAlign: "center"}}>
                                                {(key.price > 0) ? 
                                                <>
                                                <View style={{fontSize: "10px", flexDirection: "row"}}>
                                                    <Text>${(Math.round(key.price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                </View>
                                                </>
                                                : 
                                                <>
                                                    
                                                    <Text style={{flex: 2, color: "#292929", paddingTop: "6px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{(Math.round(key.price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                    <Text style={{flex: 2, color: "#292929", paddingTop: "6px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4"}}>{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                </>
                                                }
                                            </View>
                                        </View>
                                        ;
                                        })}
                                        {spaymentdate === "Commence On" &&  (
                                        <>
                                            <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px", marginTop: "5px"}}>(The payment commences on {spaymentday}/{spaymentmonth}/{spaymentyear})</Text>
                                        </>
                                        )
                                        }
                                        {spaymentdate === "Arrival Of Invoice" &&  (
                                        <>
                                            <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px", marginTop: "5px"}}>(The payment commences upon arrival of the invoice)</Text>
                                        </>
                                        )
                                        }
                                        </>
                                        
                                )} 
                                
                                {spaymentterm === "Installment" && (
                                    <>
                                    {data?.salesagreement_pays.map((key, index) => {
                                        return <View key={index} style={{display: 'flex'}}>
                                            <View style={{flexDirection: "row", textAlign: "center"}}>
                                                {(key.price > 0) ? 
                                                <>
                                                <View style={{fontSize: "10px", flexDirection: "row"}}>
                                                    <Text>${(Math.round(key.price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                    <Text style={{marginLeft: "5px", marginRight: "5px"}}>x</Text>
                                                    <Text>{key.amount}</Text>
                                                </View>
                                                </>
                                                : 
                                                <>
                                                    <Text style={{flex: 2, color: "#292929", paddingTop: "6px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{(Math.round(key.price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                    <Text style={{flex: 2, color: "#292929", paddingTop: "6px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4"}}>{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                </>
                                                }
                                            </View>
                                        </View>
                                        ;
                                        })} 
                                        {spaymentdate === "Commence On" &&  (
                                        <>
                                            <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px", marginTop: "5px"}}>(The payment commences on {spaymentday}/{spaymentmonth}/{spaymentyear})</Text>
                                        </>
                                        )
                                        }
                                        {spaymentdate === "Arrival Of Invoice" &&  (
                                        <>
                                            <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px", marginTop: "5px"}}>(The payment commences upon arrival of the invoice)</Text>
                                        </>
                                        )
                                        }
                                    </>
                                )} 


                                </View>
                            </View>
                        </View>
                        <View>
                        <Text style={{ width: "100%", border: "0.4px solid #000", marginTop: "2px", marginBottom: "16px"}}></Text>
                        </View>

                        <Text style={styles.subtitle}>5.  PAYMENT METHOD</Text>
                        <View style={styles.sub1}>
                            <View style={styles.sub1D}>
                                <View style={{marginBottom: "10px"}}>
                                    {spmethod === "Credit Card" && (
                                        <>
                                        <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>{spmethod}</Text>
                                    <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>Card Type: {scredittype}</Text>
                                    <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>Card No: {screditno}</Text>
                                    <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>Expired Date: {screditexmonth} / {screditexyear}</Text>
                                    <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>CVV: {screditcvv}</Text>
                                    </>
                                    )}
                                    {spmethod === "Transfer" && (
                                    <>
                                    <View style={{display:"flex", fontSize: "9px", flexDirection: "row"}}>
                                        <View style={styles.check}>
                                            <View style={styles.checkmark}></View>
                                        </View>
                                        <View style={{marginLeft: "6px"}}><Text style={{fontSize: "11px", marginBottom: "3px", marginTop: "2px"}}>Transfer</Text>
                                        <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>MINIMAX IMPLANT</Text>
                                        <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>BSB: 062256</Text>
                                        <Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>ACC: 10780005</Text>
                                        </View>
                                    </View>
                                    {/* <View style={{display:"flex", fontSize: "9px", flexDirection: "row", marginTop: "5px"}}>
                                        <View style={styles.check}>
                                        </View>
                                        <View style={{marginLeft: "6px"}}><Text style={{fontSize: "11px", marginBottom: "3px", marginTop: "3px"}}>Cheque</Text></View>
                                    </View> */}
                                    </>
                                    )}
                                    {spmethod === "Cheque" && (
                                    <>
                                    <View style={{display:"flex", fontSize: "9px", flexDirection: "row"}}>
                                        <View style={styles.check}>
                                            <View style={styles.checkmark}></View>
                                        </View>
                                        <View style={{marginLeft: "6px"}}><Text style={{fontSize: "11px", marginBottom: "3px", marginTop: "2px"}}>Cheque</Text>
                                        </View>
                                    </View>
                                    </>
                                    )}
                                    {spmethod === "Loan" && (
                                    <>

                                    <View style={{display:"flex", fontSize: "9px", flexDirection: "row"}}>
                                        <View style={styles.check}>
                                            <View style={styles.checkmark}></View>
                                        </View>
                                        <View style={{marginLeft: "6px"}}><Text style={{fontSize: "11px", marginBottom: "3px", marginTop: "2px"}}>Loan</Text>
                                        <View style={{display:"flex", flexDirection: "row"}}>
                                            <View><Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>Loan Company: </Text></View>
                                            <View><Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>{sloancompany}</Text></View>
                                        </View>
                                        <View style={{display:"flex", flexDirection: "row"}}>
                                            <View><Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>Representative: </Text></View>
                                            <View><Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>{sloanrep}</Text></View>
                                        </View>
                                        <View style={{display:"flex", flexDirection: "row"}}>
                                            <View><Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>Mobile No: </Text></View>
                                            <View><Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>{sloanmobile}</Text></View>
                                        </View>
                                        <View style={{display:"flex", flexDirection: "row"}}>
                                            <View><Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>Email: </Text></View>
                                            <View><Text style={{ color: '#58595B', fontSize: '9px', marginBottom: "4px", marginRight: "10px"}}>{sloanemail}</Text></View>
                                        </View>
                                        </View>
                                    </View>
                                    </>
                                    )}
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            </View>
            
        </Page>
        <Page>
            <View style={styles.body}>
            <Text style={styles.subtitle}>6.  LIST OF PRODUCTS</Text>
            </View>

            <View style={styles.body1}>
                <View style={styles.list0}>
                    <View style={{display: 'flex'}}>
                        <View style={{flexDirection: "row", textAlign: "center"}}>
                            <Text style={{flex: 1, backgroundColor: "#101031", color: "white", paddingTop: "5px", paddingBottom: "2px",}}>No</Text>
                            <Text style={{flex: 6, backgroundColor: "#101031", color: "white", paddingTop: "5px", paddingBottom: "2px"}}>Description</Text>
                            <Text style={{flex: 1, backgroundColor: "#101031", color: "white", paddingTop: "5px", paddingBottom: "2px"}}>Qty</Text>
                            <Text style={{flex: 2, backgroundColor: "#101031", color: "white", paddingTop: "5px", paddingBottom: "2px"}}>Unit Price</Text>
                            <Text style={{flex: 2, backgroundColor: "#101031", color: "white", paddingTop: "5px", paddingBottom: "2px"}}>Amount (inc. gst)</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.list0}>
                    
                    {data?.salesagreement_items.map((key, index) => {
                    
                    return <View key={index} style={{display: 'flex'}}>
                        <View style={{flexDirection: "row", textAlign: "center"}}>
                            {(key.unit_price > 0) ? 
                            <>
                                <Text style={{flex: 1, paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{index+1}</Text>
                                <Text style={{flex: 6, paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{key.description}</Text>
                                <Text style={{flex: 1, paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{key.qty}</Text>
                                <Text style={{flex: 2, paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                <Text style={{flex: 2, paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4"}}>{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                            </>
                            : 
                            <>
                                <Text style={{flex: 1, paddingTop: "5px", color: "", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{index+1}</Text>
                                <Text style={{flex: 6, color: "red", paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{key.description}</Text>
                                {(key.qty == 0) ?
                                <>
                                <Text style={{flex: 1, color: "red", paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}></Text>
                                </>
                                :
                                <>
                                <Text style={{flex: 1, color: "red", paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{key.qty}</Text>
                                </>
                                }
                                {(key.unit_price == 0) ?
                                <>
                                <Text style={{flex: 2, color: "red", paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                </>
                                :
                                <>
                                <Text style={{flex: 2, color: "red", paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                </>
                                }
                                {(key.amount == 0) ?
                                <>
                                <Text style={{flex: 2, color: "red", paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4"}}>{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                </>
                                :
                                <>
                                <Text style={{flex: 2, color: "red", paddingTop: "5px", paddingBottom: "2px", borderBottom: "1px solid #D1D3D4"}}>{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                </>
                                }
                            </>
                            }
                        </View>
                    </View>
                    ;
                    }
                    )} 
                    <View style={{display: "flex"}}>
                        <View style={{flexDirection: 'row'}}>
                            <View style={{flex: 7, padding: "10px", marginTop: "17px"}}>
                                <Text style={{color: "red", fontSize: "6px"}}>{data?.special_condition}</Text>
                            </View>
                            <View style={{flex: 3, marginTop: "10px", padding: "10px",}}>
                                <View style={{display: "flex"}}>
                                    <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
                                        <View style={{}}>
                                            <Text style={{backgroundColor: "#fff", color: "#292929", fontSize: "10px", marginBottom: "3px", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>Total:</Text>
                                            <Text style={{backgroundColor: "#fff", color: "#292929", fontSize: "10px", marginBottom: "3px", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>GST:</Text>
                                            <Text style={{backgroundColor: "#fff", color: "#292929", fontSize: "10px",  paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>Final Price:</Text>
                                        </View>
                                        <View style={{textAlign: "center", alignItems: "flex-end"}}>
                                            {(data?.sa_total_price == 0) ?
                                            <>
                                            <Text style={{fontSize: "10px",  marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Text>
                                            <Text style={{fontSize: "10px",marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Text>
                                            <Text style={{fontSize: "10px", marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Text>
                                            </>
                                            :
                                            <>
                                            <Text style={{fontSize: "10px",  marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ {Math.round(data?.sa_total_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                            <Text style={{fontSize: "10px",marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ {Math.round(data?.sa_gst_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                            <Text style={{fontSize: "10px", marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ {Math.round(data?.sa_final_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                            </>
                                            }
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        
                    </View>
                </View>
            </View>
            <View style={styles.bottom}>
                <Footer/>
            </View>
        </Page>
        {implantItem  && (
            <>
            <Page>
                <View style={styles.body}>
                    <Text style={styles.subtitle}>7.  TERMS & CONDITIONS</Text>
                    <View style={styles.sub2}>
                    <View style={{fontSize: "7.5px"}}>
<Text style={{marginBottom: "3px"}}>1. Definitions </Text>
<Text style={{marginBottom: "3px"}}>1.1 The "Client" means the person who buys or agrees to buy goods or services from the Supplier. </Text>
<Text style={{marginBottom: "3px"}}>1.2 The "Supplier" means Minimax Implant Pty Ltd.</Text>
<Text style={{marginBottom: "10px"}}>1.3 "Conditions" means the Conditions of Sale and Contract set out in this document and any special conditions agreed in writing by the Supplier. </Text>
<Text style={{marginBottom: "3px"}}>2. Conditions </Text>
<Text style={{marginBottom: "3px"}}>2.1 These "Terms and Conditions" do not affect your statutory rights as a consumer. </Text>
<Text style={{marginBottom: "3px"}}>2.2 All contracts of sale made by the Supplier shall be deemed to incorporate these terms and conditions which shall prevail over any other document or communication from the Client.  </Text>
<Text style={{marginBottom: "3px"}}>2.3 If any amendments to this terms and conditions are required it is a condition that they be confirmed in writing.  </Text>
<Text style={{marginBottom: "10px"}}>2.4 Acceptance of delivery of goods or services shall be deemed conclusive evidence of the Clients acceptance of these Conditions. </Text>

<Text style={{marginBottom: "3px"}}>3. Prices </Text>
<Text style={{marginBottom: "3px"}}>3.1 The Price shall be that on the Supplier’s current list price, or as shown on the Supplier’s web site, or the price contained in the Supplier’s specific quotation to the Client, as applies. </Text>
<Text style={{marginBottom: "3px"}}>3.2 Should a product price or service change between receipt of order and dispatch the Client will be notified and given the opportunity to cancel the order. </Text>
<Text style={{marginBottom: "3px"}}>3.3 All Prices are exclusive of GST and charges for packing, postage and carriage (plus GST) shall be paid depending on condition of sale. </Text>
<Text style={{marginBottom: "3px"}}>3.4 In the case of sales on credit, payment is due in full no later than 30 days from the date of invoice. Time for payment shall be of the essence and any failure to pay shall entitle the Supplier at its option to treat the Contract as repudiated by the Client or to delay delivery until paid. </Text>
<Text style={{marginBottom: "10px"}}>3.5 First payment or full payment shall be provided to Supplier on the contract date, or date for first payment shall be provided and must be kept according to the contract agreed between two parties. </Text>

<Text style={{marginBottom: "3px"}}>4. Temporary cease on Overdue Invoices  </Text>
<Text style={{marginBottom: "10px"}}>4.1 Temporary cease on overdue invoices shall accrue from the date when payment becomes due for over 30 days. The cease will automatically clear upon the date of payment. 10% interest may apply </Text>

<Text style={{marginBottom: "3px"}}>5. Warranty and Liability </Text>
<Text style={{marginBottom: "3px"}}>5.1 The Supplier warrants that the goods or services will at the time of delivery correspond to the description given by the Supplier. Except where the Client is dealing as a consumer all other warranties, conditions or terms relating to fitness for purpose, merchantability or condition of the goods or services, whether implied by Statute, Common Law or otherwise are excluded and the Client is satisfied as to the suitability of the goods or services for the Client’s purpose. </Text>
<Text style={{marginBottom: "10px"}}>5.2 While every effort is made to ensure that the goods or service specifications are accurate, other manufacturers do add and remove functionality in their products as products and software are developed. It is highly recommended that an evaluation of the chosen product(s) or service is carried out to ensure that it meets your functional requirements prior to purchase. </Text>

<Text style={{marginBottom: "3px"}}>6. Delivery </Text>
<Text style={{marginBottom: "3px"}}>6.1 Where a specific delivery date has been agreed, and if this delivery date cannot be met the Client will be notified and a new delivery date agreed. </Text>
<Text style={{marginBottom: "3px"}}>6.2 Whilst every reasonable effort shall be made to keep any delivery date, date and time of delivery shall not be of the essence and the Supplier shall not be liable for any losses, costs, damages or expenses incurred by the Client or any other person or Company arising directly or indirectly out of any failure to meet any estimated delivery date. </Text>
<Text style={{marginBottom: "10px"}}>6.3 Delivery of the Goods or services shall be made to the Client’s nominated delivery address and the Client shall make all arrangements necessary to take delivery of the goods or services whenever they are tendered for delivery. </Text>

<Text style={{marginBottom: "3px"}}>7. Cancellation  </Text>
<Text style={{marginBottom: "7px"}}>7.1 The Supplier reserves the right to make a handling and restocking charge of 25% on goods only which are returned if they were ordered in error or are no longer required due to change of mind.</Text>

<Text style={{marginBottom: "3px"}}>8. Liability </Text>
<Text style={{marginBottom: "10px"}}>8.1 Except as may be implied by law where the Client is dealing as a consumer, in the event of any breach of these Conditions by the Supplier the remedies of the Client shall be limited to damages which shall in no circumstances exceed the price of the goods or service provided and the Supplier shall under no circumstances be liable for any indirect, incidental or consequential damage or loss whatsoever. </Text>

<Text style={{marginBottom: "3px"}}>9. Misc </Text>
<Text style={{marginBottom: "3px"}}>9.1 Any and all contracts shall in all respects be construed and operate as an Australian contract and in conformity with Australian law.</Text>
<Text style={{marginBottom: "10px"}}>9.2 If any part of these terms and conditions that is not fundamental is found to be illegal or unenforceable, such finding will not affect the validity or enforceability of the remainder of these terms and conditions.</Text>
</View>
                    </View>
                </View>
                {/* <ProposalSignature/> */}
                <View style={styles.bottom}>
                    <Footer/>
                </View>
                {/* <Text render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}></Text> */}
            </Page>

            `<Page>
                <View style={styles.body_m}>
                    <Text style={styles.subtitle}>8.  SIGNATURE</Text>
                    <View style={styles.sub1}>
                        <View style={styles.sub1D}>
                            <View style={{marginBottom: "13px"}}>
                                <Text style={{ color: '#292929', fontSize: '10px', marginBottom: "10px", marginRight: "10px"}}>By signing below, you certify that you have read this agreement, that you know and understand the meaning and intent of this agreement and that you are entering this agreement knowingly and voluntarily.</Text>
                            </View>
                            

                        </View>
                    </View>
                    <View style={styles.signature}>
                        <View style={styles.signaguresub}>
                            <View style={{flexDirection: "row" }}>
                                <View style={{flexDirection: "column", width: "48%"}}>
                                    <View style={{backgroundColor: "#707070", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>DENTIST</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "12px", marginTop: "6px"}}>{scdentist}</Text>
                                                <Text style={{ color: "#94928D", fontSize: "7px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{backgroundColor: "#fff", height: "20px", width: "4%", color: "#fff"}}>
                                </View>
                                <View style={{flexDirection: "column", width: "48%"}}>
                                    <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>SALES REPRESENTATIVE</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "12px", marginTop: "6px"}}>{suname}</Text>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "8px"}}>{sdate}</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                            </View>
                            
                        </View>
                    </View>
                    <View style={styles.signature}>
                        <View style={styles.signaguresub}>
                            <View style={{flexDirection: "row", justifyContent: "flex-end", marginTop: "50px"}}>
                                <View style={{backgroundColor: "#fff", height: "20px", width: "52%", color: "#fff"}}>
                                </View>
                                <View style={{flexDirection: "column", width: "48%"}}>
                                    <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>MINIMAX IMPLANT PTY LTD</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "12px", marginTop: "6px"}}>Jun Park</Text>
                                                <Text style={{ color: "#94928D", fontSize: "7px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                            </View>
                            
                        </View>
                    </View>
                </View>
                <View style={styles.bottom}>
                    <Footer/>
                </View>
            </Page>

            </>
        )} 
        {equipmentItem && (
            <>
            <Page>
                <View style={styles.body}>
                    <Text style={styles.subtitle}>7.  TERMS & CONDITIONS</Text>
                    <View style={styles.sub2}>
                    <View style={{fontSize: "7.5px"}}>
<Text style={{marginBottom: "3px"}}>1. Definitions </Text>
<Text style={{marginBottom: "3px"}}>1.1 The "Client" means the person who buys or agrees to buy goods or services from the Supplier. </Text>
<Text style={{marginBottom: "3px"}}>1.2 The "Supplier" means Minimax Implant Pty Ltd.</Text>
<Text style={{marginBottom: "10px"}}>1.3 "Conditions" means the Conditions of Sale and Contract set out in this document and any special conditions agreed in writing by the Supplier. </Text>
<Text style={{marginBottom: "3px"}}>2. Conditions </Text>
<Text style={{marginBottom: "3px"}}>2.1 These "Terms and Conditions" do not affect your statutory rights as a consumer. </Text>
<Text style={{marginBottom: "3px"}}>2.2 All contracts of sale made by the Supplier shall be deemed to incorporate these terms and conditions which shall prevail over any other document or communication from the Client.  </Text>
<Text style={{marginBottom: "3px"}}>2.3 If any amendments to this terms and conditions are required it is a condition that they be confirmed in writing.  </Text>
<Text style={{marginBottom: "10px"}}>2.4 Acceptance of delivery of goods or services shall be deemed conclusive evidence of the Clients acceptance of these Conditions. </Text>

<Text style={{marginBottom: "3px"}}>3. Prices </Text>
<Text style={{marginBottom: "3px"}}>3.1 The Price shall be that on the Supplier’s current list price, or as shown on the Supplier’s web site, or the price contained in the Supplier’s specific quotation to the Client, as applies. </Text>
<Text style={{marginBottom: "3px"}}>3.2 Should a product price or service change between receipt of order and dispatch the Client will be notified and given the opportunity to cancel the order. </Text>
<Text style={{marginBottom: "3px"}}>3.3 All Prices are exclusive of GST and charges for packing, postage and carriage (plus GST) shall be paid depending on condition of sale. </Text>
<Text style={{marginBottom: "3px"}}>3.4 In the case of sales on credit, payment is due in full no later than 30 days from the date of invoice. Time for payment shall be of the essence and any failure to pay shall entitle the Supplier at its option to treat the Contract as repudiated by the Client or to delay delivery until paid. </Text>
<Text style={{marginBottom: "10px"}}>3.5 First payment or full payment shall be provided to Supplier on the contract date, or date for first payment shall be provided and must be kept according to the contract agreed between two parties. </Text>

<Text style={{marginBottom: "3px"}}>4. Temporary cease on Overdue Invoices  </Text>
<Text style={{marginBottom: "10px"}}>4.1 Temporary cease on overdue invoices shall accrue from the date when payment becomes due for over 30 days. The cease will automatically clear upon the date of payment. 10% interest may apply </Text>

<Text style={{marginBottom: "3px"}}>5. Warranty and Liability </Text>
<Text style={{marginBottom: "3px"}}>5.1 The Supplier warrants that the goods or services will at the time of delivery correspond to the description given by the Supplier. Except where the Client is dealing as a consumer all other warranties, conditions or terms relating to fitness for purpose, merchantability or condition of the goods or services, whether implied by Statute, Common Law or otherwise are excluded and the Client is satisfied as to the suitability of the goods or services for the Client’s purpose. </Text>
<Text style={{marginBottom: "10px"}}>5.2 While every effort is made to ensure that the goods or service specifications are accurate, other manufacturers do add and remove functionality in their products as products and software are developed. It is highly recommended that an evaluation of the chosen product(s) or service is carried out to ensure that it meets your functional requirements prior to purchase. </Text>

<Text style={{marginBottom: "3px"}}>6. Delivery </Text>
<Text style={{marginBottom: "3px"}}>6.1 Where a specific delivery date has been agreed, and if this delivery date cannot be met the Client will be notified and a new delivery date agreed. </Text>
<Text style={{marginBottom: "3px"}}>6.2 Whilst every reasonable effort shall be made to keep any delivery date, date and time of delivery shall not be of the essence and the Supplier shall not be liable for any losses, costs, damages or expenses incurred by the Client or any other person or Company arising directly or indirectly out of any failure to meet any estimated delivery date. </Text>
<Text style={{marginBottom: "10px"}}>6.3 Delivery of the Goods or services shall be made to the Client’s nominated delivery address and the Client shall make all arrangements necessary to take delivery of the goods or services whenever they are tendered for delivery. </Text>

<Text style={{marginBottom: "3px"}}>7. Cancellation  </Text>
<Text style={{marginBottom: "7px"}}>7.1 The Supplier reserves the right to make a handling and restocking charge of 25% on goods only which are returned if they were ordered in error or are no longer required due to change of mind.</Text>

<Text style={{marginBottom: "3px"}}>8. Liability </Text>
<Text style={{marginBottom: "10px"}}>8.1 Except as may be implied by law where the Client is dealing as a consumer, in the event of any breach of these Conditions by the Supplier the remedies of the Client shall be limited to damages which shall in no circumstances exceed the price of the goods or service provided and the Supplier shall under no circumstances be liable for any indirect, incidental or consequential damage or loss whatsoever. </Text>

<Text style={{marginBottom: "3px"}}>9. Misc </Text>
<Text style={{marginBottom: "3px"}}>9.1 Any and all contracts shall in all respects be construed and operate as an Australian contract and in conformity with Australian law.</Text>
<Text style={{marginBottom: "10px"}}>9.2 If any part of these terms and conditions that is not fundamental is found to be illegal or unenforceable, such finding will not affect the validity or enforceability of the remainder of these terms and conditions.</Text>
</View>
                    {/* 
                        {data?.satncs.map((key, index) => {
                        return <Text key={index}>
                            <Text style={{fontSize: "7.5px"}}>{key.description}</Text>
                        </Text>
                        ;
                        } )} */}
                    </View>
                </View>
                {/* <ProposalSignature/> */}
                <View style={styles.bottom}>
                    <Footer/>
                </View>
                {/* <Text render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}></Text> */}
            </Page>
            <Page>
                <View style={styles.body}>
                    <Text style={styles.subtitle}>8.  CUSTOMER SERVICE POLICY</Text>
                    <View style={styles.sub2}>
                        <View style={{fontSize: "7.5px"}}>
<Text style={{marginBottom: "3px"}}>1. Purpose </Text>
<Text style={{marginBottom: "10px", marginLeft: "3px"}}>- This policy outlines the guidelines for providing and managing technical services at Minimax. It aims to ensure consistent and high-quality service delivery and to offer effective technical support to both customers and internal employees. </Text>
<Text style={{marginBottom: "3px"}}>2. Scope </Text>
<Text style={{marginBottom: "10px", marginLeft: "3px"}}>- This policy applies to all employees, contractors, and external service providers involved in delivering technical services at Minimax.</Text>
<Text style={{marginBottom: "3px"}}>3. Policy Statement</Text>
<Text style={{marginBottom: "3px", marginLeft: "3px"}}>3.1 Service Standards </Text>
<Text style={{marginBottom: "1px", marginLeft: "6px"}}>- Technical service requests must be responded to within 24 hours.</Text>
<Text style={{marginBottom: "1px", marginLeft: "6px"}}>- The maximum response time for issue resolution is 1 week.</Text>
<Text style={{marginBottom: "3px", marginLeft: "6px"}}>- Service requests are categorized and prioritized based on their urgency and impact.</Text>
<Text style={{marginBottom: "3px", marginLeft: "3px"}}>3.2 Service Standards </Text>
<Text style={{marginBottom: "1px", marginLeft: "6px"}}>- Appropriate teams or technical experts will be assigned to resolve issues promptly.</Text>
<Text style={{marginBottom: "3px", marginLeft: "6px"}}>- The status of issues will be updated regularly, and significant changes will be communicated to the requester.</Text>
<Text style={{marginBottom: "3px", marginLeft: "3px"}}>3.3 Service Pricing: </Text>
<Text style={{marginBottom: "3px", marginLeft: "6px"}}>3.3.1 Onsite Service:</Text>
<Text style={{marginBottom: "1px", marginLeft: "9px"}}>- Base Charge: $150 + GST for the initial time</Text>
<Text style={{marginBottom: "1px", marginLeft: "9px"}}>- Additional Time: $100 + GST per hour</Text>
<Text style={{marginBottom: "1px", marginLeft: "9px"}}>- F.g. 2 hours of service: $250 + GST</Text>
<Text style={{marginBottom: "3px", marginLeft: "9px"}}>- Additional travel charges may apply for distances exceeding 100 Km.</Text>
<Text style={{marginBottom: "3px", marginLeft: "6px"}}>3.3.2 Remote Service:</Text>
<Text style={{marginBottom: "1px", marginLeft: "9px"}}>- Service charges apply after 15 minutes of remote access to the computer</Text>
<Text style={{marginBottom: "10px", marginLeft: "9px"}}>* If the warranty period of the equipment has been expired.</Text>
<Text style={{marginBottom: "3px"}}>4. Responsibilities and Roles</Text>
<Text style={{marginBottom: "3px", marginLeft: "3px"}}>4.1 Technical Support Team </Text>
<Text style={{marginBottom: "1px", marginLeft: "6px"}}>- Responsible for responding to and resolving all technical service requests.</Text>
<Text style={{marginBottom: "3px", marginLeft: "6px"}}>- Provides technical advice and support to customers and employees.</Text>
<Text style={{marginBottom: "3px", marginLeft: "3px"}}>4.2 Users </Text>
<Text style={{marginBottom: "1px", marginLeft: "6px"}}>- Must provide accurate descriptions of issues when making service requests.</Text>
<Text style={{marginBottom: "10px", marginLeft: "6px"}}>- Should follow provided instructions and procedures.</Text>
<Text style={{marginBottom: "3px"}}>5. Security and Data Protection</Text>
<Text style={{marginBottom: "1px", marginLeft: "3px"}}>- All technical support activities must comply with Minimax's security policies and data protection regulations.</Text>
<Text style={{marginBottom: "10px", marginLeft: "3px"}}>- Sensitive information will be handled securely and protected from unauthorized access.</Text>
<Text style={{marginBottom: "3px"}}>6. Policy Violations</Text>
<Text style={{marginBottom: "10px", marginLeft: "3px"}}>- Violations of this policy will be addressed with appropriate actions, including possible disciplinary measures if necessary.</Text>
<Text style={{marginBottom: "3px"}}>7. Policy Review and Updates</Text>
<Text style={{marginBottom: "1px", marginLeft: "3px"}}>- This policy will be reviewed regularly and updated as needed.</Text>
<Text style={{marginBottom: "10px", marginLeft: "3px"}}>- All changes to the policy will be communicated to relevant parties.</Text>
<Text style={{marginBottom: "3px"}}>8. Contact Information</Text>
<Text style={{marginBottom: "10px", marginLeft: "3px"}}>- For questions or additional information regarding this policy, please contact +61 409161661 or ts@minimaximplant.com.au</Text>
<Text style={{marginBottom: "3px"}}>9. Warranty</Text>
<Text style={{marginBottom: "1px", marginLeft: "3px"}}>- The warranty period varies by equipment model and may change annually.</Text>
<Text style={{marginBottom: "10px", marginLeft: "3px"}}>- Some equipment may have options for extending The warranty period.</Text>

                        </View>
{/* 
                        {data?.satncs.map((key, index) => {
                        return <Text key={index}>
                            <Text style={{fontSize: "7.5px"}}>{key.description}</Text>
                        </Text>
                        ;
                        } )} */}
                    </View>
                </View>
                {/* <ProposalSignature/> */}
                <View style={styles.bottom}>
                    <Footer/>
                </View>
                {/* <Text render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}></Text> */}
            </Page>

            <Page>
                <View style={styles.body_m}>
                    <Text style={styles.subtitle}>9.  SIGNATURE</Text>
                    <View style={styles.sub1}>
                        <View style={styles.sub1D}>
                            <View style={{marginBottom: "13px"}}>
                                <Text style={{ color: '#292929', fontSize: '10px', marginBottom: "10px", marginRight: "10px"}}>By signing below, you certify that you have read this agreement, that you know and understand the meaning and intent of this agreement and that you are entering this agreement knowingly and voluntarily.</Text>
                            </View>
                            

                        </View>
                    </View>
                    <View style={styles.signature}>
                        <View style={styles.signaguresub}>
                            <View style={{flexDirection: "row" }}>
                                <View style={{flexDirection: "column", width: "48%"}}>
                                    <View style={{backgroundColor: "#707070", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>DENTIST</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "12px", marginTop: "6px"}}>{scdentist}</Text>
                                                <Text style={{ color: "#94928D", fontSize: "7px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{backgroundColor: "#fff", height: "20px", width: "4%", color: "#fff"}}>
                                </View>
                                <View style={{flexDirection: "column", width: "48%"}}>
                                    <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>SALES REPRESENTATIVE</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "12px", marginTop: "6px"}}>{suname}</Text>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "8px"}}>{sdate}</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                            </View>
                            
                        </View>
                    </View>
                    <View style={styles.signature}>
                        <View style={styles.signaguresub}>
                            <View style={{flexDirection: "row", justifyContent: "flex-end", marginTop: "50px"}}>
                                <View style={{backgroundColor: "#fff", height: "20px", width: "52%", color: "#fff"}}>
                                </View>
                                <View style={{flexDirection: "column", width: "48%"}}>
                                    <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>MINIMAX IMPLANT PTY LTD</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "12px", marginTop: "6px"}}>Jun Park</Text>
                                                <Text style={{ color: "#94928D", fontSize: "7px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                
                            </View>
                            
                        </View>
                    </View>
                </View>
                <View style={styles.bottom}>
                    <Footer/>
                </View>
            </Page>
            </>
        )} 
        

    </Document>
    )
    
    function CheckUser() {
        if(userData?.role === "Boss" && data?.salesagreement_id || userData?.name ==="Charlie Hong" && data?.salesagreement_id || userData?.name ==="Sean Hyun" && data?.salesagreement_id || userData?.role ==="Manager" && data?.salesagreement_id) { 
            return <>
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <Box><Link to={`/salesagreements/`}><Button color="#292929" variant="outline" colorScheme='facebook'>Go Back</Button></Link></Box>
                    <Box><PDFDownloadLink document={<MyPDF />} fileName={`${data?.user.name}_${data?.salesagreement_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                {/* Quotation Information */}
                
                {/* <Container>
                <HStack ><Box>TITLE: </Box><Box>{data?.title}</Box></HStack>
                </Container> */}
                <Box paddingRight="10" paddingLeft="10">
                <HStack mb="7"><Box fontSize="20" fontWeight="bold"></Box><Box fontSize="20" fontWeight="bold">{data?.client.name}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.client.clinic}</Box></HStack>

                <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                    <Flex>
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <Flex mr={5}>
                                    <Box>
                                        <Box>Phone:</Box>
                                        <Box>Email:</Box>
                                        <Box>Address:</Box>
                                        <Box>ABN:</Box>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box>
                                        <Box>{data?.client.mobile}</Box>
                                        <Box>{data?.client.email}</Box>
                                        <Box>{data?.client.address}</Box>
                                        <Box>{data?.client.abn}</Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex>
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <Flex mr={5}>
                                    <Box>
                                        <Box>Sales Agreement ID:</Box>
                                        <Box>Sales Rep:</Box>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box>
                                        <Box>{data?.salesagreement_id}</Box>
                                        <Box>{data?.user.name}</Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                </Box>
                
                <Box textAlign="center" mt={{base: "40px", lg: "80px"}} mb="20">This Sales Agreement (the "Agreement") is made on and effective as of <b>{data?.date}</b></Box>
                <Box paddingRight="10" paddingLeft="10">
                <Box fontWeight="700">1. PARTIES</Box>
                    <Box mt="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>This Agreement is between MINIMAX IMPLANT PTY LTD [ABN: 60 154 715 705] of Suite 2.3, Level 2 South Tower 394 Lane Cove Rd, Macquarie Park NSW 2113 (TEL: 02 8084 2900) ("Supplier")</Box>
                    <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>And {data?.client.clinic} [ABN: {data?.client.abn}] ("Client")</Box>
                <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>2. CONSIDERATION</Box>
                    <Box mt="5" mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>The Client wishes to purchase products from the Supplier on the terms and conditions contained in this Agreement.</Box>
                <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>3. PAYMENT TERM</Box>
                    <Box mt="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>{data?.payment_term}</Box>
                    <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>(The payment commences on <b>{data?.payday}/{data?.paymonth}/{data?.payyear}</b>)</Box>
                    {data?.salesagreement_pays.map((key, index) => <Flex key={index}>
                    <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>{key.price} x {key.amount}</Box>
                    </Flex> 
                    )}
                    {/* `data?.salesagreement_pays.${index}.price`  */}
                <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>4. PAYMENT METHOD</Box>
                    <Box mt="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>{data?.payment_method}</Box>
                    {spmethod === "Credit Card" && (
                        <>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Credit Card: <span>{data?.credit_type}</span></Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Card No: <span>{data?.credit_no}</span></Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Expired Date: <span>{data?.credit_exmonth} / {data?.credit_exyear}</span></Box>
                            <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>CVV: <span>{data?.credit_cvv}</span></Box>
                        </>
                    )}
                    {spmethod === "Transfer" && (
                        <>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Minimax Implant</Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>BSB: 062256</Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>ACC: 10780005</Box>
                        </>
                    )}
                    {spmethod === "Cheque" && (
                        <>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Minimax Implant</Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>BSB: 062256</Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>ACC: 10780005</Box>
                        </>
                    )}
                    {spmethod === "Loan" && (
                        <>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Dentist Name: <span>{data?.client.name}</span></Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Loan Company: <span>{data?.loan_company}</span></Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Rep: <span>{data?.loan_rep}</span></Box>
                            <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Mobile: <span>{data?.loan_mobile}</span></Box>
                            <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Email: <span>{data?.loan_email}</span></Box>
                        </>
                    )}
                <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>5. LIST OF PRODUCTS</Box>
                
                {/* Quotation Itmes */}
                <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={{base: "10px", lg:"10px"}}>
                <Flex mt={{base: "10px", lg:"10px"}} bg="#292929" color="white" pt="7px" pb="7px">
                    <Box flex="1">ID</Box>
                    <Box flex="6">Description</Box>
                    <Box flex="1">QTY</Box>
                    <Box flex="2">UNIT PRICE</Box>
                    <Box flex="2">AMOUNT</Box>
                </Flex>
                
                {data?.salesagreement_items.map((key, index) => <Flex key={index}>
                    
                {(key.unit_price > 0) ?  
                <>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.description}</Box>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </> 
                : 
                <>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.description}</Box>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </> 
                }
                    
                </Flex> 
                )}
                </Box>

                <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                    <Flex flex="7">
                        <Box>
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <Box color="red" fontSize="10px"><pre><Box>{data?.special_condition}</Box></pre></Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex flex={3}></Flex>
                    <Flex flex="0 0 250px" justifyContent="flex-end">
                        <Box fontSize="18px" background="#292929" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                    </Box>
                                </Flex>
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box>{Math.round(data?.sa_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sa_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sa_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>6. TERMS & CONDITIONS</Box>
                    
                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                <Box paddingRight="10" paddingLeft="10" fontSize={{base: "8px", lg: "0.6vw"}}>

                {data?.satncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20} textOverflow="ellipsis">{ key.description }</Box></pre>
                </Box> )}
                </Box>
                </Box>
            </>

        } else if (userData?.role === "Sales Team" && data?.salesagreement_id) {
            if(minimax_number === data?.user.minimax_number) {
                return <>
                <Box 
                    pb={20}
                    pt={20}
                    px={{  
                        base: 10,
                        md: 20,
                        lg: "80px",
                    }}
                    backgroundColor="#f9f9f9"
                    borderRadius="20px"
                    >
                    <Box display="flex" justifyContent="space-between" mb="20">
                        <Box><Link to={`/salesagreements/`}><Button colorScheme='facebook'>Go Back</Button></Link></Box>
                        <Box><PDFDownloadLink document={<MyPDF />} fileName={`${data?.user.name}_${data?.salesagreement_id}`}>
                        {({loading}) => 
                            loading? (
                            <>Loading Document...</>
                            ) : (
                                <Button colorScheme='facebook'><VscFilePdf /></Button>
                            )
                        }
                        </PDFDownloadLink>
                        </Box>
                    </Box>
                    {/* Quotation Information */}
                    
                    {/* <Container>
                    <HStack ><Box>TITLE: </Box><Box>{data?.title}</Box></HStack>
                    </Container> */}
                    <Box paddingRight="10" paddingLeft="10">
                    <HStack mb="7"><Box fontSize="20" fontWeight="bold">Dr. </Box><Box fontSize="20" fontWeight="bold">{data?.client.name}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.client.clinic}</Box></HStack>
    
                    <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Phone:</Box>
                                            <Box>Email:</Box>
                                            <Box>Address:</Box>
                                            <Box>ABN:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.client.mobile}</Box>
                                            <Box>{data?.client.email}</Box>
                                            <Box>{data?.client.address}</Box>
                                            <Box>{data?.client.abn}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Sales Agreement ID:</Box>
                                            <Box>Sales Rep:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.salesagreement_id}</Box>
                                            <Box>{data?.user.name}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                    </Box>
                    
                    <Box textAlign="center" mt={{base: "40px", lg: "80px"}} mb="20">This Sales Agreement (the "Agreement") is made on and effective as of <b>{data?.date}</b></Box>
                    <Box paddingRight="10" paddingLeft="10">
                    <Box fontWeight="700">1. PARTIES</Box>
                        <Box mt="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>This Agreement is between MINIMAX IMPLANT PTY LTD [ABN: 60 154 715 705] of Suite 2.3, Level 2 South Tower 394 Lane Cove Rd, Macquarie Park NSW 2113 (TEL: 02 8084 2900) ("Supplier")</Box>
                        <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>And {data?.client.clinic} [ABN: {data?.client.abn}] ("Client")</Box>
                    <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>2. CONSIDERATION</Box>
                        <Box mt="5" mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>The Client wishes to purchase products from the Supplier on the terms and conditions contained in this Agreement.</Box>
                    <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>3. PAYMENT TERM</Box>
                        <Box mt="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>{data?.payment_term}</Box>
                        <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>(The payment commences on <b>{data?.payday}/{data?.paymonth}/{data?.payyear}</b>)</Box>
                    <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>4. PAYMENT METHOD</Box>
                        <Box mt="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>{data?.payment_method}</Box>
                        {spmethod === "Credit Card" && (
                            <>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Credit Card: <span>{data?.credit_type}</span></Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Card No: <span>{data?.credit_no}</span></Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Expired Date: <span>{data?.credit_exmonth} / {data?.credit_exyear}</span></Box>
                                <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>CVV: <span>{data?.credit_cvv}</span></Box>
                            </>
                        )}
                        {spmethod === "Transfer" && (
                            <>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Minimax Implant</Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>BSB: 062256</Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>ACC: 10780005</Box>
                            </>
                        )}
                        {spmethod === "Cheque" && (
                            <>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Minimax Implant</Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>BSB: 062256</Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>ACC: 10780005</Box>
                            </>
                        )}
                        {spmethod === "Loan" && (
                            <>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Dentist Name: <span>{data?.client.name}</span></Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Loan Company: <span>{data?.loan_company}</span></Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Rep: <span>{data?.loan_rep}</span></Box>
                                <Box paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Mobile: <span>{data?.loan_mobile}</span></Box>
                                <Box mb="5" paddingRight="5" paddingLeft="5" fontSize={{base: "10px", lg: "14px"}}>Email: <span>{data?.loan_email}</span></Box>
                            </>
                        )}
                    <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>5. LIST OF PRODUCTS</Box>
                    
                    {/* Quotation Itmes */}
                    <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={{base: "10px", lg:"10px"}}>
                    <Flex mt={{base: "10px", lg:"10px"}} bg="#385898" color="white" pt="7px" pb="7px">
                        <Box flex="1">ID</Box>
                        <Box flex="6">Description</Box>
                        <Box flex="1">QTY</Box>
                        <Box flex="2">UNIT PRICE</Box>
                        <Box flex="2">AMOUNT</Box>
                    </Flex>
                    
                    {data?.salesagreement_items.map((key, index) => <Flex key={index}>
                        
                    {(key.unit_price > 0) ?  
                    <>
                        <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                        <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.description}</Box>
                        <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                        <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                        <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    </> 
                    : 
                    <>
                        <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                        <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.description}</Box>
                        <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                        <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                        <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    </> 
                    }
                        
                    </Flex> 
                    )}
                    </Box>
    
                    <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                        <Flex flex="7">
                            <Box>
                                <Box display="flex">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box color="red" fontSize="10px"><pre><Box>{data?.special_condition}</Box></pre></Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                        <Flex flex={3}></Flex>
                        <Flex flex="0 0 250px" justifyContent="flex-end">
                            <Box fontSize="18px" background="#385898" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                                <Box display="flex">
                                    <Flex mr={5}>
                                        <Box>
                                            <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                            <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                            <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                        </Box>
                                    </Flex>
                                    <Flex mr={5}>
                                        <Box>
                                            <HStack mb="10px"><Box>{Math.round(data?.sa_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                            <HStack mb="10px"><Box>{Math.round(data?.sa_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                            <HStack mb="10px"><Box>{Math.round(data?.sa_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                    <Box fontWeight="700" mt={{base: "30px", lg: "60px"}}>6. TERMS & CONDITIONS</Box>
                        
                    </Box>
                    {/* Special Condition */}
    
                    {/* Terms & Condition */}
                    <Box paddingRight="10" paddingLeft="10" fontSize={{base: "8px", lg: "0.6vw"}}>
    
                    {data?.satncs.map((key, index) => <Box key={index}>
                        <pre><Box mt={20} textOverflow="ellipsis">{ key.description }</Box></pre>
                    </Box> )}
                    </Box>
                    </Box>
                </>
            } else {
                navigate("/")
            }
        } else {
            navigate("/")
        }
        return <Box>{data?.salesagreement_id}</Box>
    }
    return (
        <Box>
        {isLoading ? (
            <>
            <SalesAgreementDetailSkeleton />
            </>
        ) : null }
            <Box>
                <CheckUser />
            </Box>
        </Box>
    )
}