import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, HStack, Skeleton, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, Link, Stack, Flex, FormControl, InputGroup, InputLeftElement, IconButton, Input, Spacer, useColorModeValue } from "@chakra-ui/react";
import { VscFilePdf } from 'react-icons/vsc';
import RepairSkeleton from "../../components/Repair/RepairSkeleton";
import Repairs from '../../components/Repair/Repairs';
import { useQuery } from '@tanstack/react-query';
import { IRepairList } from "../../types";
import { getMe, getRepairs } from '../../api';
import useUser from '../../lib/useUser';
import { SearchIcon } from '@chakra-ui/icons';

interface IUser {
    id: number;
    name: string;
    username: string;
    role: string;
    minimax_number: string;
}
export default function Repair(props: {
	variant?: string;
	background?: string;
	children?: JSX.Element;
	placeholder?: string;
	borderRadius?: string | number;
	[x: string]: any;
}) {

    const { variant, background, children, placeholder, borderRadius, ...rest } = props;
	// Chakra Color Mode
	const searchIconColor = useColorModeValue('gray.700', 'white');
	const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const inputText = useColorModeValue('gray.700', 'gray.100');

    const { isLoading, data } = useQuery<IRepairList[]>(["repairs"], getRepairs);
    const { data: userData, isLoading: isUserLoading } = useQuery<IUser>([`users`], getMe);
    const minimax_number = userData?.minimax_number;
    const [query, setQuery] = useState("");
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let inputHandler = (e:any) => {
                var lowerCase = e.target.value.toLowerCase();
                setQuery(lowerCase)
            }
        },3000)
        return () => clearTimeout(delayDebounceFn)
    }, [])    
    
    let repairList;
    function RoleCheck() {
        if(userData?.role === "Boss" || userData?.name==="Charlie Hong" || userData?.name==="Sean Hyun" || userData?.role === "TS Team" ) {
            return <Tbody>
            {data?.filter((data) => { 
                if(query === '') { 
                    return data;
                } else if (data.repair_id.toLowerCase().includes(query) || data.submitter.name.toLowerCase().includes(query)  || data.client.name.toLowerCase().includes(query) || data.client.clinic.toLowerCase().includes(query)  || data.kind.toLowerCase().includes(query)  || data.product_name.toLowerCase().includes(query) || data.whyrepair.toLowerCase().includes(query)) {
                    return data;
                }
                }).map((repair) => (
            repairList = <Repairs
            key={repair.id}
            id={repair.id}
            repair_id={repair.repair_id}
            submitter={repair.submitter.name}
            dentist={repair.client.name}
            clinic={repair.client.clinic}
            kind={repair.kind}
            product_name={repair.product_name}
            whyrepair={repair.whyrepair}
            submission_date={repair.submission_date}
            /> 
            ))}
            </Tbody>
        }   else if(userData?.role === "TS Team") {
            return <Tbody>
            {data?.filter(data=>data.submitter.minimax_number==minimax_number).filter((data) => data.repair_id.toLowerCase().includes(query) || data.submitter.name.toLowerCase().includes(query)  || data.client.name.toLowerCase().includes(query) || data.client.clinic.toLowerCase().includes(query)  || data.kind.toLowerCase().includes(query)  || data.product_name.toLowerCase().includes(query) || data.whyrepair.toLowerCase().includes(query)).map((repair) => (
            repairList = <Repairs
            key={repair.id}
            id={repair.id}
            repair_id={repair.repair_id}
            submitter={repair.submitter.name}
            dentist={repair.client.name}
            clinic={repair.client.clinic}
            kind={repair.kind}
            product_name={repair.product_name}
            whyrepair={repair.whyrepair}
            submission_date={repair.submission_date}
            /> 
            ))}
            </Tbody>
        }   else if(userData?.role === "Sales Team") {
            return <Tbody>
            {data?.filter(data=>data.submitter.minimax_number==minimax_number).filter((data) => data.repair_id.toLowerCase().includes(query) || data.submitter.name.toLowerCase().includes(query)  || data.client.name.toLowerCase().includes(query) || data.client.clinic.toLowerCase().includes(query)  || data.kind.toLowerCase().includes(query)  || data.product_name.toLowerCase().includes(query) || data.whyrepair.toLowerCase().includes(query)).map((repair) => (
            repairList = <Repairs
            key={repair.id}
            id={repair.id}
            repair_id={repair.repair_id}
            submitter={repair.submitter.name}
            dentist={repair.client.name}
            clinic={repair.client.clinic}
            kind={repair.kind}
            product_name={repair.product_name}
            whyrepair={repair.whyrepair}
            submission_date={repair.submission_date}
            /> 
            ))}
            </Tbody>
        }
        return repairList = <Tbody><Tr><Td></Td></Tr></Tbody>
    }
    return (
        <Grid mt={10} columnGap={7} rowGap={5} templateColumns={{ base: "repeat(1fr)", lg: "repeat(1fr)"}} px={{base: 0, lg: 10}}>
            {isLoading ? (
            <>
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                <RepairSkeleton />
                
            </>
            ) : null }
            <Flex>
            <FormControl>
            <InputGroup w={{ base: '100%', md: '50%' }} {...rest}>
                <InputLeftElement
                    children={
                        <IconButton
                            aria-label='search'
                            bg='none'
                            borderRadius='inherit'
                            _hover={{
                                bg: 'none',
                            }}
                            _active={{
                                bg: 'none',
                                transform: 'none',
                                borderRadius: 'inherit',
                                borderColor: 'transparent'
                            }}
                            _focus={{
                                boxShadow: 'none',
                                borderRadius:'inherit'
                            }}
                            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' borderRadius='inherit'
                            />}
                        />
                    }
                />
                <Input
                    variant='search'
                    border="1px solid #f0f0f0"

                    fontSize='sm'
                    color={inputText}
                    fontWeight='500'
                    _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                    borderRadius={borderRadius ? borderRadius : '30px'}
                    placeholder={placeholder ? placeholder : 'Search...'}
                    type="text"
                    onChange={(e) => setQuery(e.target.value)}
                    />
            </InputGroup>
            </FormControl>
            
            <Spacer />

            <Link href="/repairs/upload">
                <Button size="sm">+ New Repair</Button>
            </Link>
            </Flex>
            <Box>
                <HStack>
                    <TableContainer w="100%">
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>Ref. No</Th>
                                    <Th>작성자</Th>
                                    <Th>의사이름</Th>
                                    <Th>클리닉이름</Th>
                                    <Th>제품명</Th>
                                    <Th>Warranty</Th>
                                    <Th>고장원인</Th>
                                </Tr>
                            </Thead>
                            <RoleCheck />
                        </Table>
                    </TableContainer>
                </HStack>
            </Box>
        </Grid>
    )
}  