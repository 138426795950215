import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom"
import { getMe, getBoard } from "../../api";
import { IBoardDetail, IUser } from "../../types";
import BoardDetailSkeleton from "../../components/Board/BoardDetailSkeleton";
import { Badge, Box, Button, Heading, HStack, Stack } from "@chakra-ui/react";
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import Footer from "../../components/PDF/Repair/Footer";
import Header from "../../components/PDF/Repair/Header";
import { VscFilePdf } from "react-icons/vsc";
import { useMemo } from "react";
import Header2 from "../../components/PDF/Repair/Header2";


const styles = StyleSheet.create({
    body: {
    backgroundColor: '#ffffff',
    fontFamiy: 'Source Sans',
    fontSize: 12,
    lineHeight: 1.4,
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 32,
    height: '100vh',
    },
    top: {
        flex: 1,
    },
    middle1: {
        flex: 3,
        justifyContent: 'flex-start',
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 20,
        paddingLeft: 20,
    },
    bottom: {
        justifyContent: "flex-end",
        flex: 1,
    },
})

export default function RepairDetail() {
    const { boardPk } = useParams();
    const navigate = useNavigate();

    const { isLoading, data } = useQuery<IBoardDetail>([`boards`, boardPk], getBoard);
    const { data: userData } = useQuery<IUser>([`users`], getMe);
    const minimax_number = userData?.minimax_number;
    const qid = data?.board_id!



    function CheckUser() {
        if(userData?.role === "Boss" || "TS Team" || "Admin Team" || "Sales Team" || "Management Team" || "Manager" || "Marketing Team" && data?.board_id) {
            return <>
                <HStack>
                <Link to={`/boards/`}><Button>Go Back</Button></Link>
                
                </HStack>
                <Box><pre>{data?.description}</pre></Box>
                </>
        } 
        // else if (userData?.role === "Sales Team" && data?.board_id) {
        //     if(minimax_number === data?.submitter.minimax_number) {
        //         return <>
        //         <Heading>{data?.title}</Heading>
        //         <Box><pre>{data?.description}</pre></Box>
        //         </>
        //     } else {
        //         navigate("/")
        //     }
        // } 
        else {
            navigate("/")
        }
        return <Box>{data?.board_id}</Box>
    }
    return (
        <Box>
        {isLoading ? (
            <>
            <BoardDetailSkeleton />
            </>
        ) : null }
            <Box>
                <CheckUser />
            </Box>
        </Box>
    )
}